import React, { useContext, useMemo, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts';
import { TripProvider } from '../contexts';
import { DriverProvider } from '../contexts';
import { CustomerProvider } from '../contexts';
import { CustomerTripProvider } from '../contexts';
import { ContactsProvider } from '../contexts';
import { Helmet } from 'react-helmet-async';


// All layouts/containers
import DefaultLayout from '../layouts/Default';
import HorizontalLayout from '../layouts/Horizontal/';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import ErrorPageNotFound from '../pages/error/PageNotFound';
import LogRocket from 'logrocket';

const AuthorizedRoutes = (props) => {
  const { user } = useContext(UserContext);

  const role = useMemo(() => {
    return user?.role;
  }, [user]);

  return (<Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
    <HorizontalLayout {...props}>
      <Switch>
        {authProtectedFlattenRoutes.map((route, index) => {
          let { path, roles, component, exact, children, ...rest } = route;

          return (
            !children && (
              <route.route
                key={index}
                path={path}
                roles={roles}
                exact={exact}
                component={component}
                {...rest}
              />
            )
          );
        })}
        {/* <Route path="/*" component={ErrorPageNotFound} /> */}
        <Redirect to="/404" />
      </Switch>
    </HorizontalLayout>
  </Route>);
};

const Routes = (props) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('ee9kgs/errands-admin');
    }
  }, []);

  const { user } = useContext(UserContext);
  const role = useMemo(() => {
    return user?.role;
  }, [user]);

  return (
    <Switch>
      <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
        <DefaultLayout {...props}>
          <Switch>
            {publicProtectedFlattenRoutes.map((route, index) => {
              return (
                !route.children && (
                  <route.route
                    key={index}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    component={route.component}
                  />
                )
              );
            })}
            {/* <Route path="/*" component={ErrorPageNotFound} /> */}
            <Redirect to="/404" />
          </Switch>
        </DefaultLayout>
      </Route>

      {role === 'Dispatcher' ?
        <>
          <Helmet>
            <title>Errands admin portal</title>
          </Helmet>
          <TripProvider>
            <DriverProvider>
              <CustomerProvider>
                <ContactsProvider>
                  <AuthorizedRoutes />
                 </ContactsProvider>
              </CustomerProvider>
            </DriverProvider>
          </TripProvider>
        </>

        : role === 'Customer' ?
          <>
            <Helmet>
              <title>Errands customer portal</title>
            </Helmet>
            <CustomerTripProvider>
              <AuthorizedRoutes />
            </CustomerTripProvider>
          </>
          : <AuthorizedRoutes />}
    </Switch>
  );
};

export default Routes;
