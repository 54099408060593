import React from 'react';
import { Route } from 'react-router-dom';
import ErrorPageNotFound from '../pages/error/PageNotFound';
import PrivateRoute from './PrivateRoute';
import DefaultRoute from './DefaultRoute';

// lazy load all the views
const Driverpage = React.lazy(() => import('../driver'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const ForgotPassword = React.lazy(() => import('../pages/account/ForgotPassword'));
const SetPassword = React.lazy(() => import('../pages/account/SetPassword'));
const VerifyEmail = React.lazy(() => import('../pages/account/VerifyEmail'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));
const ExternalInvoice = React.lazy(() => import('../pages/billing/ExternalInvoice'));

// dispatcher routes
const Dashboard = React.lazy(() => import('../custom/Dashboard'));
// const EditableTable_New = React.lazy(() => import('../custom/EditableTabel_3'));
const DispatchPage = React.lazy(() => import('../pages/DispatchPage'));
const DispatchMobilePage = React.lazy(() => import('../custom/DispatchMobilePage'));
const ScheduledTrips = React.lazy(() => import('../pages/ScheduledTrips'));
const AllTrips = React.lazy(() => import('../custom/AllTrips'));
const History = React.lazy(() => import('../custom/History'));
const TripDetail = React.lazy(() => import('../custom/TripDetail'));
const AddNewForm = React.lazy(() => import('../custom/AddNewForm'));
const Customers = React.lazy(() => import('../pages/customer/Customers'));
const Billing = React.lazy(() => import('../pages/billing/Billing'));
const Settings = React.lazy(() => import('../pages/Settings'));
const CustomerList = React.lazy(() => import('../pages/customer/CustomerList'));
const DriverList = React.lazy(() => import('../pages/driver/DriverList'));
const DriverDetails = React.lazy(() => import('../pages/driver/DriverDetails'));
const DeletedTrips = React.lazy(() => import('../custom/DeletedTrips'));
const BulkUploadOld = React.lazy(() => import('../pages/BulkUpload/BulkUploadOld'));
const BulkUpload = React.lazy(() => import('../pages/BulkUpload'));
const PrintLabels = React.lazy(() => import('../pages/PrintLabels/PrintLabels'));
const FlaggedTrips = React.lazy(() => import('../pages/FlaggedTrips'));
const Reports = React.lazy(() => import('../pages/Reports/index'));
const MergeCustomers = React.lazy(() => import('../pages/customer/MergeCustomers'));
const Contacts = React.lazy(() => import('../pages/contacts/ContactsList'));


// customer routes
const OpenOrders = React.lazy(() => import('../customers/OpenOrders'));
const OrderHistory = React.lazy(() => import('../customers/OrderHistory'));
const PaymentMethods = React.lazy(() => import('../customers/PaymentMethods'));
const AccountSettings = React.lazy(() => import('../customers/Settings'));
const InvoiceRoute = React.lazy(() => import('./InvoiceRoute'));


// auth & public routes
const authRoutes = [
  {
    path: '/account/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
  {
    path: '/account/register',
    name: 'Register',
    component: Register,
    route: Route,
    exact: true,
  },
  {
    path: '/account/forgotpassword',
    name: 'Forgot Password',
    component: ForgotPassword,
    route: Route,
  },
  {
    path: '/account/lock-screen',
    name: 'Lock Screen',
    component: LockScreen,
    route: Route,
  },
  {
    path: '/account/setpassword',
    name: 'Set Password',
    component: SetPassword,
    route: Route,
    exact: true,
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    component: VerifyEmail,
    route: Route,
  },
  {
    path: '/404',
    name: 'Not Found',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/external-pay',
    name: 'External Pay',
    component: ExternalInvoice,
    route: Route,
  },
];

const myRoutes = [
  {
    path: '/',
    exact: true,
    component: DefaultRoute,
    route: PrivateRoute,
    roles: ['Dispatcher', 'Customer', 'Driver'],
  },
  {
    path: '/pro',
    name: 'Driver',
    component: Driverpage,
    route: PrivateRoute,
    roles: ['Driver'],
  },
  {
    path: '/dispatch',
    name: 'Dispatch',
    component: DispatchPage,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/dispatch-tablet',
    name: 'Dispatch',
    component: DispatchMobilePage,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/stage',
    name: 'Stage',
    component: ScheduledTrips,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/all-orders',
    name: 'Trips',
    component: AllTrips,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/tripdetail',
    name: 'Trip Detail',
    component: TripDetail,
    route: PrivateRoute,
    roles: ['Dispatcher', 'Customer'],
  },
  {
    path: '/pros',
    name: 'DriversList',
    component: DriverList,
    route: PrivateRoute,
    module: 'drivers',
    exact: true,
    roles: ['Dispatcher'],
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomerList,
    route: PrivateRoute,
    exact: true,
    roles: ['Dispatcher'],
  },
  {
    path: '/customer/:id',
    name: 'Customer Details',
    component: Customers,
    route: PrivateRoute,
    module: 'customers',
    roles: ['Dispatcher'],
  },
  // {
  //     path: '/driver/:id',
  //     name: 'Driver Details',
  //     component: Drivers,
  //     route: PrivateRoute,
  //     module: 'drivers',
  //     roles: ['Dispatcher'],
  // },
  {
    path: '/pros/new',
    name: 'New Pro',
    component: AddNewForm,
    route: PrivateRoute,
    module: 'drivers',
    exact: true,
    roles: ['Dispatcher'],
  },
  {
    path: '/pros/:id',
    name: 'Pros Details',
    component: DriverDetails,
    route: PrivateRoute,
    module: 'drivers',
    roles: ['Dispatcher'],
  },

  {
    path: '/customers/new',
    name: 'New Customer',
    component: AddNewForm,
    route: PrivateRoute,
    module: 'customers',
    exact: true,
    roles: ['Dispatcher'],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    route: PrivateRoute,
    exact: true,
    roles: ['Dispatcher'],
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    // DO NOT delete this route, it is needed for backward compatibility
    path: '/customerdashboard',
    name: 'Customer Dashboard',
    component: OpenOrders,
    route: PrivateRoute,
    exact: true,
    roles: ['Customer'],
  },
  {
    path: '/openorders',
    name: 'Open Orders',
    component: OpenOrders,
    route: PrivateRoute,
    exact: true,
    roles: ['Customer'],
  },
  {
    path: '/orderhistory',
    name: 'Order History',
    component: OrderHistory,
    route: PrivateRoute,
    exact: true,
    roles: ['Customer'],
  },
  {
    path: '/settings/:type',
    name: 'zones',
    component: Settings,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/deleted-trips',
    name: 'DeletedTrips',
    component: DeletedTrips,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/orders',
    name: 'History',
    component: History,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/bulk-upload-old',
    name: 'Bulk Upload Old',
    component: BulkUploadOld,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/bulk-upload',
    name: 'Bulk Upload',
    component: BulkUpload,
    route: PrivateRoute,
    roles: ['Dispatcher', 'Customer'],
  },
  {
    path: '/paymentmethods',
    name: 'Payment Methods Page',
    component: PaymentMethods,
    route: PrivateRoute,
    roles: ['Customer'],
  },
  {
    path: '/accountsettings',
    name: 'Account',
    component: AccountSettings,
    route: PrivateRoute,
    roles: ['Customer'],
  },
  {
    path: '/mergeCustomer',
    name: 'Merge Customer',
    component: MergeCustomers,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
  {
    path: '/print-labels',
    name: 'Print Labels',
    component: PrintLabels,
    route: PrivateRoute,
    roles: ['Dispatcher'],
    action: 'post',
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoiceRoute,
    route: PrivateRoute,
    roles: ['Dispatcher', 'Customer'],
  },
  {
    path: '/flagged-trips/:type',
    name: 'Flagged Trips',
    component: FlaggedTrips,
    route: PrivateRoute,
    roles: ['Dispatcher'],
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
//const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes, pageRoutes, uiRoutes];
//const publicRoutes = [...authRoutes, ...otherPublicRoutes];
const authProtectedRoutes = [...myRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
